// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Nunito:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// Variables
@import 'variables';
@import 'globalstyle';
@import 'style';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {color: $blue;}
.indigo {color: $indigo;}
.purple {color: $purple;}
.pink {color: $pink;}
.red {color: $red;}
.orange {color: $orange;}
.yellow {color: $yellow;}
.green {color: $green;}
.teal {color: $teal;}
.cyan {color: $cyan;}

body {
  margin: 0;
  font-family:$font-family-sans-serif !important;
  font-size:$font-size-base !important;
  font-weight: 400;
  line-height: $line-height-base !important;
}

.content-header{
  padding: 10px 0.5rem !important;
}

.vs__search,
.vs__search:focus {
  font-size: 0.9rem !important;
  padding: 0.3rem 0.6rem !important;
  margin: 0 !important;
  color: #6c757d !important;
  border-radius: 0 !important;
}

.vs__dropdown-toggle {
    border-radius: 0 !important;
}
