/* slide-down */
.slide-down-enter-active {
    -moz-transition-duration: 0.65s;
    -webkit-transition-duration: 0.65s;
    -o-transition-duration: 0.65s;
    transition-duration: 0.65s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-down-leave-active {
    -moz-transition-duration: 0.65s;
    -webkit-transition-duration: 0.65s;
    -o-transition-duration: 0.65s;
    transition-duration: 0.65s;
    -moz-transition-timing-function: cubic-bezier(
        0,
        0.25,
        0.5,
        1,
        0.25,
        0.5,
        1
    );
    -webkit-transition-timing-function: cubic-bezier(
        0,
        0.25,
        0.5,
        1,
        0.25,
        0.5,
        1
    );
    -o-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1, 0.25, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1, 0.25, 0.5, 1);
}

.slide-down-enter-to,
.slide-down-leave {
    max-height: 300px;
    overflow: hidden;
}

.slide-down-enter,
.slide-down-leave-to {
    overflow: hidden;
    max-height: 0;
}

/* Fade Animation */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.font-size-060rem {
    font-size: 0.6rem !important;
}
.font-size-065rem {
    font-size: 0.65rem !important;
}
.font-size-070rem {
    font-size: 0.7rem !important;
}
.font-size-075rem {
    font-size: 0.75rem !important;
}
.font-size-080rem {
    font-size: 0.8rem !important;
}
.font-size-085rem {
    font-size: 0.85rem !important;
}
.font-size-090rem {
    font-size: 0.9rem !important;
}
.font-size-095rem {
    font-size: 0.95rem !important;
}
.font-size-100rem {
    font-size: 1rem !important;
}
.font-size-105rem {
    font-size: 1.05rem !important;
}
.font-size-110rem {
    font-size: 1.1rem !important;
}
.font-size-115rem {
    font-size: 1.15rem !important;
}
.font-size-120rem {
    font-size: 1.2rem !important;
}
.font-size-125rem {
    font-size: 1.25rem !important;
}
.font-size-150rem {
    font-size: 1.5rem !important;
}
.font-size-160rem {
    font-size: 1.6rem !important;
}

/* CSS for Login page */

.half,
.half .container > .row {
    height: 100vh;
    min-height: 600px;
}

.b-form-datepicker {
    border-radius: 0 !important;
}

@media (max-width: 991.98px) {
    .half .bg {
        height: 200px;
    }
}

.half .contents {
    background: #f6f7fc;
}

.half .contents,
.half .bg {
    width: 50%;
}
@media (max-width: 1199.98px) {
    .half .contents,
    .half .bg {
        width: 100%;
    }
}

.half .bg {
    background-size: cover;
    background-position: center;
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.rotate {
    animation: rotate 1s linear infinite;
}

.border-dashed {
    border: 1px dashed #dee2e6 !important;
}

@media (max-width: 768px) {
    .table-hover th,
    .table-hover td {
        padding: 8px;
        text-align: left;
        white-space: nowrap;
        max-width: 100vw;
        overflow: auto;
    }
    .table-hover td {
        padding-right: 15px !important;
    }
    .b-form-datepicker {
        min-width: 200px !important;
    }
}
